import { useState, useEffect } from 'react'
import { Breadcrumb, Layout, Skeleton } from "antd"
import { Helmet } from "react-helmet"
import { Content } from "antd/es/layout/layout"
import { useParams } from 'react-router-dom'

import Nav from "../../components/Nav"
import Header from "../../components/Header"
import useVPNBot from "../../hooks/useVPNBot"
import KeysMenu from '../../components/Menu/KeysMenu'
import KeysTab from '../../components/Tab/KeysTab'
import BreadcrumbLoader from '../../components/BreadcrumbLoader'



const KeysPage = () => {

    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const { useServer } = useVPNBot()
    const { server, getServer, loading } = useServer
    const { server_id } = useParams()
    const breadcrumbs = [
        {title: 'Servers', href: '/servers'},
        {title: server?.name, /*href: `/items/${item?.id}`*/}
    ]

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        if (server_id) getServer(parseInt(server_id))
    }, [server_id])

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            <Helmet>
                <title>{`${server?.name ?? 'Server'} keys`} - VPNBot Admin</title>
            </Helmet>
            <Layout style={{ minHeight: '100vh' }}>
                <Header/>
                <Layout>
                    <Nav/>
                    <Layout className="site-layout">
                        <div className="tab__menu">
                            <h1 className="tab__title">Keys</h1>
                            <KeysMenu/>
                        </div>
                        <Content className="bg-[#f0f2f5]" style={{ margin: '0 16px' }}>
                            <div className='mt-[32px] p-[24px] bg-white rounded-[4px]'>
                                <KeysTab/>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </>
    )
}

export default KeysPage;