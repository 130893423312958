import { Action, ActionType, initialState, State } from '../types/actions/tab';

const userReducer = (state: State = initialState, action: Action): State =>
{
    switch(action.type)
    {
        case ActionType.TAB_SET:
            return {...state, ...action};
        default:
            return state;
    }
}

export default userReducer;