import {useState, useEffect} from 'react'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'
import { getTariffsAction, getServerTariffsAction, getTariffByUUIDAction, deleteTariffAction } from '../actions/tariff'
import Tariff from '../types/models/tariff'
import Item from '../types/models/item'
import Meta from '../types/models/meta'
import Server from '../types/models/server'
import { ActionType } from '../types/actions/tariff'


const useTariff = () =>
{
    const selector = useAppSelector((state) => state.tariffs)
    const dispatch = useAppDispatch()

    const [tariffs, setTariffs] = useState<Tariff[]>([])
    const [tariff, setTariff] = useState<Tariff>()
    const [meta, setMeta] = useState<Meta|undefined>(selector?.meta)
    const [errors, setErrors] = useState(selector.errors)
    const [success, setSuccess] = useState(selector.success)
    const [loading, setLoading] = useState(selector.loading)
    const [deleted, setDeleted] = useState(selector.type === ActionType.TARIFF_DELETE)

    const getTariffByUUID = (uuid: Tariff["uuid"]) =>
    {
        dispatch(getTariffByUUIDAction(uuid));
    }

    //TODO getItemTariffs
    const getTariffs = (page?: number, item?: Item["name"], date?: string) =>
    {
        dispatch(getTariffsAction(page, item, date))
    }

    const getServerTariffs = (server: Server["id"], page?: number) =>
    {
        dispatch(getServerTariffsAction(server, page))
    }

    const deleteTariff = (id: Tariff["id"]) =>
    {
        dispatch(deleteTariffAction(id))
    }

    useEffect(() => {
        setTariffs(selector?.data ? selector.data : tariffs)
        setTariff(selector?.data ? selector.data[0] : tariff)
        setLoading(selector.loading)
        setErrors(selector.errors)
        setMeta(selector.meta)
        setSuccess(selector.success)
        setDeleted(selector.type === ActionType.TARIFF_DELETE)
    }, [selector])

    return {
        tariffs,
        tariff,
        loading,
        meta,
        errors,
        success,
        deleted,
        getTariffs,
        getServerTariffs,
        getTariffByUUID,
        deleteTariff
    }
}

export default useTariff