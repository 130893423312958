import React, { useEffect, useState } from 'react';
import { message, Table } from 'antd';
import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import useDetailClient from '../../../hooks/useDetailClient';
import Spinner from '../../Spinner';
import './index.scss';
import UserModal from "../../Modal/UserModal";

interface IUserTabProps {
    onClientNameChange: (name: string) => void;
}

const UserTab: React.FC<IUserTabProps> = ({ onClientNameChange }) => {
    const [messageApi] = message.useMessage();
    const { user_id } = useParams<{ user_id: string }>();
    const { client, loading, refetch } = useDetailClient(Number(user_id));
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [visible, setVisible] = useState(false);
    const [currentRecord, setCurrentRecord] = useState<any>(null);

    useEffect(() => {
        if (client?.tariffs) {
            onClientNameChange(client.name);
            setDataSource(client.tariffs.map((tariff: any) => ({
                key: tariff.id,
                id: tariff.id,
                uuid: tariff.uuid,
                creation_date: tariff.created_at,
                expired_date: tariff.expired_at,
                name: tariff.item.name,
                access_url: tariff.key.access_url,
                current_server: tariff.key.server.country.short,
                servers: tariff.item.servers,
            })));
        }
    }, [client, onClientNameChange]);

    const onItemEdit = (record: any) => {
        setCurrentRecord(record);
        setVisible(true);
    };

    const copyKey = (key: string) => {
        navigator.clipboard.writeText(key);
        messageApi.open({
            type: 'success',
            content: `Key successfully copied! `,
        });
    };

    const handleApply = async () => {
        setVisible(false);
        await refetch();
    };

    const columns = [
        {
            title: 'Creation Date',
            dataIndex: 'creation_date',
            key: 'creation_date',
        },
        {
            title: 'Item',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Expiration Date',
            dataIndex: 'expired_date',
            key: 'expired_date',
        },
        {
            title: 'Key',
            dataIndex: 'access_url',
            key: 'access_url',
            render: (text: any, record: any) => (
                <>
                    {text.length ? <CopyOutlined onClick={() => copyKey(record.access_url)} className='copy-icon mr-3' /> : ''}
                    {text.substring(0, 10)}
                </>
            ),
        },
        {
            title: 'Actions',
            key: 'id',
            render: (text: any, record: any) => (
                <EditOutlined onClick={() => onItemEdit(record)} className='copy-icon mr-3' />
            ),
        },
    ];

    return (
        <>
            <Helmet>
                <title>{`${client?.name ?? 'Клиент'} - VPNBot Admin`}</title>
            </Helmet>

            <UserModal
                title='Редактировать тариф'
                buttonTitle='Save'
                show={visible}
                handleShow={setVisible}
                source={currentRecord || {}}
                handleApply={handleApply}
                servers={currentRecord?.servers || []}
            />
            {loading ? (
                <div className='flex justify-items-center items-center flex-col w-full'>
                    <Spinner />
                </div>
            ) : (
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    className="user__tab"
                    pagination={false}
                />
            )}
        </>
    );
};

export default UserTab;
