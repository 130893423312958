import { AppThunk } from '../store'
import { ActionType } from '../types/actions/client'
import { getClients, addClient, deleteClient, editClient, getClientById } from '../api/clientAPI'
import Client from '../types/models/client'
import Item from '../types/models/item'
import Server from '../types/models/server'


const getClientsAction = (name?: string, page?: number): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.CLIENT_LOADING
    })
    getClients(name, page).then((response) => {
        const clientResponse = response.data as Client[]
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.CLIENT_GETS,

            ...clientResponse
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.CLIENT_ERROR,
            ...exception.response.data
        })
    });
}

const getDetailClientAction = (id: number): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.CLIENT_LOADING
    })
    getClientById(id).then((response) => {
        const clientResponse = response.data as Client[]
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.CLIENT_GETS,

            ...clientResponse
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.CLIENT_ERROR,
            ...exception.response.data
        })
    });
}

const addClientAction = (client: Client, item?: Item, server?: Server, expired_at?: string): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.CLIENT_LOADING
    })
    addClient(client, item, server, expired_at).then((response) => {
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.CLIENT_POST,
            success: response.data.success
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.CLIENT_ERROR,
            ...exception.response.data
        })
    });
}

const deleteClientAction = (id: Client["id"]): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.CLIENT_LOADING
    })
    deleteClient(id).then((response) => {
        const clientResponse = response.data as Client[]
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.CLIENT_DELETE,
            success: response.data.success
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.CLIENT_ERROR,
            ...exception.response.data
        })
    });
}

const updateClientAction = (client: Client, item?: Item, server?: Server): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.CLIENT_LOADING
    })
    editClient(client, item, server).then((response) => {
        const clientResponse = response.data as Client[]
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.CLIENT_DELETE,
            success: response.data.success
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.CLIENT_ERROR,
            ...exception.response.data
        })
    });
}

export { getClientsAction, getDetailClientAction, addClientAction, deleteClientAction, updateClientAction }

