import { Action, ActionType, initialState, State } from '../types/actions/item';

const itemReducer = (state: State = initialState, action: Action): State =>
{
    //console.log({...state, ...action})
    switch(action.type)
    {
        case ActionType.ITEM_DELETE:
        case ActionType.ITEM_ERROR:
        case ActionType.ITEM_POST:
        case ActionType.ITEM_GET:
        case ActionType.ITEM_GETS:
        case ActionType.ITEM_LOADING:
        case ActionType.ITEM_PUT:
            return {...state, ...action};
        default:
            return state;
    }
}

export default itemReducer;