import { Layout } from "antd"
import { Helmet } from "react-helmet"
import { Content } from "antd/es/layout/layout"

import Nav from "../../components/Nav"
import Header from "../../components/Header"
import ServersMenu from '../../components/Menu/ServersMenu'
import ServersTab from '../../components/Tab/ServersTab'
import useVPNBot from "../../hooks/useVPNBot"
import { useEffect } from "react"


const ServersPage = () => {

    const { useServer, useCountry } = useVPNBot()
    const { getServers } = useServer
    const { getCountries } = useCountry

    useEffect(getServers, [])
    useEffect(getCountries, [])

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            <Helmet>
                <title>Servers - VPNBot Admin</title>
            </Helmet>
            <Layout style={{ minHeight: '100vh' }}>
                <Header/>
                <Layout>
                    <Nav/>
                    <Layout className="site-layout">
                        <div className="tab__menu">
                            <h1 className="tab__title">Servers</h1>
                            <ServersMenu/>
                        </div>
                        <Content className="bg-[#f0f2f5]" style={{ margin: '0 16px' }}>
                            <div className='mt-[32px] p-[24px] bg-white rounded-[4px]'>
                                <ServersTab/>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </>
    )
}

export default ServersPage;