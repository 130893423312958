import { Layout } from "antd"
import { Helmet } from "react-helmet"
import { Content } from "antd/es/layout/layout"

import Nav from "../../components/Nav"
import Header from "../../components/Header"
import PromocodesMenu from "../../components/Menu/PromocodesMenu"
import PromocodesTab from "../../components/Tab/PromocodesTab"


const  PromocodesPage = () => {

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            <Helmet>
                <title>Promocodes - VPNBot Admin</title>
            </Helmet>
            <Layout style={{ minHeight: '100vh' }}>
                <Header/>
                <Layout>
                    <Nav/>
                    <Layout className="site-layout">
                        <div className="tab__menu">
                            <h1 className="tab__title">Promocodes</h1>
                            <PromocodesMenu/>
                        </div>
                        <Content className="bg-[#f0f2f5]" style={{ margin: '0 16px' }}>
                            <div className='mt-[32px] p-[24px] bg-white rounded-[4px]'>
                                <PromocodesTab/>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </>
    )
}

export default PromocodesPage;