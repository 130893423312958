import { Action, ActionType, initialState, State } from '../types/actions/tariff'

const tariffReducer = (state: State = initialState, action: Action): State =>
{
    //console.log({...state, ...action})
    switch(action.type)
    {
        case ActionType.TARIFF_LOADING:
        case ActionType.TARIFF_ERROR:
        case ActionType.TARIFF_GETS:
        case ActionType.TARIFF_GET:
        case ActionType.TARIFF_DELETE:
            return {...state, ...action};
        default:
            return state;
    }
}

export default tariffReducer