import apiResponse from "../models/apiResponse"
import reducerState from "../models/reducerState"
import TabItem from "../models/tabItem"

export enum ActionType{
    TAB_SET         = 'TAB_SET',
    TAB_BREADCRUMBS = 'TAB_BREADCRUMBS'
}

interface actionSet
{
    type: ActionType.TAB_SET,
}

interface actionBreadcrumbs
{
    type: ActionType.TAB_BREADCRUMBS,
}

export type Action = actionSet | actionBreadcrumbs

export type State = reducerState & apiResponse<TabItem>

export const initialState: State =
{
    type: undefined,
    data: undefined,
    success: false
}
