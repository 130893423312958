import { useContext } from 'react'
import { Menu, Layout } from 'antd'
import { FilterContext } from '../../providers/FilterProvider'
import useVPNBot from '../../hooks/useVPNBot'
import NavItems from '../NavItems'

import './index.scss'

const { Sider } = Layout;

const Nav = () =>
{
    const items = NavItems()
    const { usePagination } = useContext(FilterContext)
    const { useTab } = useVPNBot()
    const { setTab, getItem, key } = useTab
    const { clearPagination } = usePagination

    const onMenuClick = (e: any) =>
    {
        clearPagination()
        setTab(getItem(e.key))
    }

    return (
        <Sider width={200}>
            <Menu onClick={onMenuClick} selectedKeys={[key.toString()]} defaultSelectedKeys={[key.toString()]} mode="inline" items={items} theme="dark" />
        </Sider>
    )
}



export default Nav