import { useEffect } from 'react'
import { Button, Col, Form, Input, Modal, Row, Select } from "antd"
import useVPNBot from '../../../hooks/useVPNBot'
import Promocode from '../../../types/models/promocode'

interface IProps
{
    title: string,
    buttonTitle: string,
    show: boolean,
    handleShow: ((state: boolean) => any)
    source: Promocode,
    handleSource: ((name: string, value: string) => any),
    handleApply: (() => void)
}


const PromocodesModal = ({title, buttonTitle, show, handleShow, source, handleSource, handleApply}: IProps) =>
{
    const [form] = Form.useForm()
    const typeOptions = [
        {label: 'Reusable', value: 'reusable'},
        {label: 'Single', value: 'single'}
    ]

    useEffect(() => form.setFieldsValue(source), [source])

    return (
        <Modal
            title={title}
            centered
            open={show}
            onOk={form.submit}
            onCancel={() => handleShow(false)}
            footer={[
                <Button key="cancel" onClick={() => handleShow(false)}>
                    Cancel
                </Button>,
                <Button form={title} key="submit" htmlType="submit" type='primary'>
                    {buttonTitle}
                </Button>
            ]}
        >
            <Form
                name={title}
                autoComplete="off"
                layout="vertical"
                id={title}
                form={form}
                onFinish={handleApply}
                initialValues={source}
            >
                <Row>
                    <Col span={24}  className="pr-1">
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input promocode name' }]}
                        >
                            <Input placeholder='Name' name='name' value={source.name} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}  className="pr-1">
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: false }]}
                        >
                            <Input placeholder='promocode description' name='description' value={source.description} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12} className='pr-1'>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: true, message: 'Please select type'}]}
                        >
                            <Select
                                value={ typeOptions.find(o => o.value == source.type.toString()) }
                                onChange={(value: any) => handleSource('type', value)}
                                options={typeOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}  className="pl-1">
                        <Form.Item
                            label="Discount"
                            name="discount"
                            rules={[{ required: true, message: 'Please input promocode discount' }]}
                        >
                            <Input type='number' placeholder='%' name='discount' value={source.discount} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>)
}

export default PromocodesModal