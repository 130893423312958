import { Action, ActionType, initialState, State } from '../types/actions/country';

const countryReducer = (state: State = initialState, action: Action): State =>
{
    //console.log({...state, ...action})
    switch(action.type)
    {
        case ActionType.COUNTRY_DELETE:
        case ActionType.COUNTRY_ERROR:
        case ActionType.COUNTRY_POST:
        case ActionType.COUNTRY_GET:
        case ActionType.COUNTRY_GETS:
        case ActionType.COUNTRY_LOADING:
        case ActionType.COUNTRY_PUT:
            return {...state, ...action};
        default:
            return state;
    }
}

export default countryReducer;