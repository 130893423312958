import Client from '../types/models/client'
import Item from '../types/models/item'
import Promocode from '../types/models/promocode'
import Server from '../types/models/server'
import {request} from './index'
import TokenConfig from './token'

const getClients = (name?: string, page?: number) => {
    return request.get('clients', {
        headers: TokenConfig().headers,
        params: {
            name: name,
            page: page
        }
    })
}

const getClientById = (id: Client["id"]) => {
    return request.get(`clients/${id}`, {
        headers: TokenConfig().headers
    });
}

const deleteClient = (id: Promocode["id"]) =>
{
    return request.delete(`clients/${id}`, TokenConfig());
}

const addClient = (client: Client, item?: Item, server?: Server, expired_at?: string) =>
{
    return request.post('clients', {
        chat_id: client.chat_id,
        name: client.name,
        item: item?.name ? item.name : undefined,
        server: server?.name ? server.name : undefined,
        expired_at: expired_at
    }, TokenConfig())
}

const editClient = (client: Client, item?: Item, server?: Server) =>
{
    return request.put(`clients/${client.id}`, {
        name: client.name,
        item: item?.name,
        server: server?.name
    }, TokenConfig())
}

export { getClients, getClientById, deleteClient, addClient, editClient };