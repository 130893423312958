import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import './index.scss'

const Spinner = () =>
{
    return (
    <>
        <Spin style={{width: 100, height: 100}} indicator={<LoadingOutlined style={{ fontSize: 100, width: 100, height: 100 }}/>}/>
    </>
    )
}

export default Spinner