import { AppDispatch, AppThunk } from '../store'
import { ActionType } from '../types/actions/country'
import { get } from '../api/countryAPI'

const getCountriesAction = (): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.COUNTRY_LOADING
    })
    get().then((response) => {
        dispatch({
            loading: false,
            type: ActionType.COUNTRY_GETS,
            data: response.data.data,
            meta: response.data.meta
        })
    }).catch((exception) => {
        dispatch({
            loading: false,
            type: ActionType.COUNTRY_ERROR,
            errors: [exception.message]
        })
    })
}

export { getCountriesAction }