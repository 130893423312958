import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space } from "antd"
import Item from '../../../types/models/item'
import Server from '../../../types/models/server'
import useVPNBot from "../../../hooks/useVPNBot"
import { useEffect, useState } from "react"
import Client from "../../../types/models/client"
import Tariff from "../../../types/models/tariff"
import dayjs, { Dayjs } from 'dayjs';

interface ISource
{
    chat_id: Client['chat_id'],
    name: Client['name'],
    item: Item['name'],
    server: Server['name'],
    expired_at?: Dayjs
}

interface IProps
{
    title: string,
    buttonTitle: string,
    show: boolean,
    handleShow: ((state: boolean) => any)
    source: ISource,
    handleSource: ((name: string, value: any) => any),
    handleApply: (() => void)
}


const UsersModal = ({title, buttonTitle, show, handleShow, source, handleSource, handleApply}: IProps) =>
{
    const { useItem, useServer } = useVPNBot()
    const { items, getItems } = useItem
    const { servers, getServers } = useServer
    const { Option } = Select
    const [form] = Form.useForm()
    const [lastItem, setLastItem] = useState(source.item)

    useEffect(getItems, [])
    useEffect(() => {
        form.setFieldsValue(source)

        if (source.item !== lastItem)
        {
            getServers(source.item)
            setLastItem(source.item)
            handleSource('server', '')

            if (items)
            {
                const selectedItem = items?.filter((item: Item, index: number, array: Item[]) => {
                    return item.name === source.item
                })[0]
                if (selectedItem)
                    handleSource('expired_at', dayjs().add(selectedItem.period, 'day'))
            }
        }
    }, [source])

    return (
        <Modal
            title={title}
            centered
            open={show}
            onOk={form.submit}
            onCancel={() => handleShow(false)}
            footer={[
                <Button key="cancel" onClick={() => handleShow(false)}>
                    Cancel
                </Button>,
                <Button form={title} key="submit" htmlType="submit">
                    {buttonTitle}
                </Button>
            ]}
        >
            <Form
                name={title}
                autoComplete="off"
                layout="vertical"
                id={title}
                form={form}
                onFinish={handleApply}
                initialValues={source}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="User ID"
                            name="chat_id"
                            rules={[{ required: true, message: 'Please input user ID' }]}
                        >
                            <Input name='chat_id' value={source.chat_id} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Username"
                            name="name"
                            rules={[{ required: false, message: 'Please input username' }]}
                        >
                            <Input name='name' value={source.name} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Item"
                            name="item"
                        >
                            <Select
                                value={{value: source.item, label: source.item}}
                                options={items?.map((item: Item) =>{ return {value: item.name, label: item.name}})}
                                onChange={(value: any) => handleSource('item', value)}
                                loading={useItem.loading}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {source.item &&
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Server"
                                name="server"
                            >
                                <Select
                                    value={{value: source.server, label: source.server}}
                                    onChange={(value: any) => handleSource('server', value)}
                                    loading={useItem.loading || useServer.loading}
                                >
                                    {servers?.map((server: Server, id: number) =>
                                        <Option value={server.name} text={server.name} disabled={server.available === 0}>
                                            <span>{server.name}</span><span className="float-right">{server.available === -1 ? '∞' : server.available}</span>
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                }
                {source.item &&
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Expiration Date"
                                name="expired_at"
                            >
                                <DatePicker className='w-[270px]' value={source.expired_at} onChange={(date: Dayjs|null, dateString: string) => handleSource('expired_at', date)} format={'DD.MM.YYYY'} />
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </Form>
        </Modal>)
}

export default UsersModal