import {request} from './index'
import TokenConfig from './token'

const login = (username: string, password: string) =>
{
    return request.post('auth/login', {
        'name': username,
        'password': password
    })
}

const logout = () =>
{
    return request.post('auth/logout')
}

const refresh = () =>
{
    return request.post('auth/refresh', undefined, TokenConfig());
}

const getUser = () =>
{
    return request.get('auth', { headers: TokenConfig().headers })
}


export {login, logout, refresh, getUser}