import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const usePagination = () =>
{
    const setPagination = (page: number) =>
    {
        paginator.set('page', page.toString())
        setPaginator(paginator)
    }

    const getPagination = () =>
    {
        return parseInt((paginator.get('page') ?? "1"))
    }

    const clearPagination = () =>
    {
        paginator.delete('page')
        setPaginator(paginator)
        setPage(1)
    }

    const [paginator, setPaginator] = useSearchParams()
    const [page, setPage] = useState(getPagination())

    return {
        page,
        setPagination,
        getPagination,
        clearPagination
    }
}

export default usePagination