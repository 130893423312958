import { useState, useEffect } from 'react'
import { Button, message } from 'antd'
import useVPNBot from '../../../hooks/useVPNBot'

import Item from '../../../types/models/item'
import ItemsModal from '../../Modal/ItemsModal'



const ItemsMenu = () =>
{
    const { useItem, useServer } = useVPNBot()
    const { addItem, items, errors, success, loading } = useItem

    const [messageApi, contextHolder] = message.useMessage();
    const [visible, setVisible] = useState(false)
    const [fields, setFields] = useState({
        name: '',
        price: 1,
        period: 1,
        servers: [],
        invisible: false,
    })

    useEffect(() => {
        messageApi.destroy()
        errors?.map((value: string, index: number) => {
            messageApi.open({
                type: 'error',
                content: value,
            });
        });
    }, [errors])

    useEffect(() => {
        if (success)
            setVisible(false)
    }, [loading])

    const onFieldChange = (name: string, value: any) =>
    {
        setFields({
            ...fields,
            [name]: value
        })
    }

    const onAddItem = () =>
    {
        const item = {
            name: fields.name,
            price: fields.price,
            period: fields.period,
            invisible: fields.invisible
        } as Item
        addItem(item, fields.servers);
    }

    return (
    <>
        {contextHolder}
        <Button type="primary" onClick={() => setVisible(true)} disabled={useItem.loading || useServer.loading}>Add item</Button>
        <ItemsModal
            title='Add item'
            buttonTitle='Add'
            source={fields}
            show={visible}
            handleApply={onAddItem}
            handleShow={(state: boolean) => setVisible(state)}
            handleSource={onFieldChange}
        />
    </>
    )
}

export default ItemsMenu