import { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Modal, Row, Select } from "antd"
import useVPNBot from '../../../hooks/useVPNBot'

interface ISource
{
    available: number,
    amount: number
}

interface IProps
{
    title: string,
    buttonTitle: string,
    show: boolean,
    handleShow: ((state: boolean) => any)
    source: ISource,
    handleSource: ((name: string, value: string) => any),
    handleApply: (() => void)
}


const KeysModal = ({title, buttonTitle, show, handleShow, source, handleSource, handleApply}: IProps) =>
{
    const [form] = Form.useForm()

    useEffect(() => form.setFieldsValue(source), [source])

    return (
        <Modal
            title={title}
            centered
            open={show}
            onOk={form.submit}
            onCancel={() => handleShow(false)}
            footer={[
                <Button key="cancel" onClick={() => handleShow(false)}>
                    Cancel
                </Button>,
                <Button form={title} key="submit" htmlType="submit">
                    {buttonTitle}
                </Button>
            ]}
        >
            <Form
                name={title}
                autoComplete="off"
                layout="vertical"
                id={title}
                form={form}
                onFinish={handleApply}
                initialValues={source}
            >
                <Row className='mb-[16px]'>
                    <Col span={24}>
                        <label className='!text-[16px] !font-normal'>Available keys for generation: {source.available == -1 ? '∞' : source.available}</label>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Key amount"
                            name="amount"
                            rules={[{ required: true, message: 'Please input key amount' }]}
                        >
                            <Input type='number' name='amount' value={source.amount} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>)
}

export default KeysModal