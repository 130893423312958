import Item from '../types/models/item'
import Server from '../types/models/server'
import { request } from './index'
import TokenConfig from './token'

const getItems = (page?: number) => {
    return request.get('items', {headers: TokenConfig().headers, params: {page: page}});
}

const getItem = (id: number) => request.get(`items/${id}`, {headers: TokenConfig().headers})

const addItem = (item?: Item, servers?: Array<Server["name"]>) =>
{
    const params = {
        name: item?.name,
        price: item?.price,
        period: item?.period,
        invisible: item?.invisible,
        servers: servers
    }
    return request.post('items',  params ,{headers: TokenConfig().headers});
}

const deleteItem = (id: number) =>
{
    return request.delete(`items/${id}`, TokenConfig());
}

const updateItem = (item: Item, servers?: Array<Server["name"]>) =>
{
    return request.put(`items/${item.id}`, {
        name: item.name,
        price: item.price,
        period: item.period,
        invisible: item.invisible,
        servers: servers
    }, TokenConfig())
}

export { getItems, getItem, addItem, deleteItem, updateItem };