import { useSearchParams } from 'react-router-dom'

const useFilter = () =>
{
    const [filter, updateFilter] = useSearchParams()
    const getFilter = (name: string): any => filter.get(name)
    const clearFilter = (name: string): void => filter.delete(name)
    const setFilter = (name: string, value: string): void =>
    {
        filter.set(name, value.toString())
        updateFilter(filter, {replace: true})
    }

    return {
        getFilter,
        setFilter,
        clearFilter
    }
}

export default useFilter