import { useState, useEffect } from 'react'
import { Button, message } from "antd"
import useVPNBot from '../../../hooks/useVPNBot'
import KeysModal from '../../Modal/KeysModal'
import { useParams } from 'react-router-dom'

const KeysMenu = () =>
{
    const { useKey, useServer } = useVPNBot()
    const { server } = useServer
    const { addKey, errors, keys, success, loading } = useKey
    const { server_id } = useParams()
    const [messageApi, contextHolder] = message.useMessage()
    const [visible, setVisible] = useState<boolean>(false)
    const [fields, setFields] = useState<any>({
        amount: 0,
        available: 0
    })

    const generateKey = () => {
        if (server) addKey(fields.amount, server.id)
    }

    const onFieldChange = (name: string, value: string) => {
        setFields({
            ...fields,
            [name]: value
        })
    }

    const onKeyGenerate = () =>
    {
        setFields({
            ...fields,
            available: server?.available
        })
        setVisible(true)
    }

    useEffect(() => {
        if (success) setVisible(false)
    }, [keys])

    useEffect(() => {
        messageApi.destroy()
        errors?.map((value: string, index: number) => {
            messageApi.open({
                type: 'error',
                content: value,
            });
        });
    }, [errors])

    return <>
        {contextHolder}
        <Button type="primary" onClick={onKeyGenerate} disabled={loading}>Generate</Button>
        <KeysModal
            title={'Generate key'}
            buttonTitle={'Generate'}
            source={fields}
            show={visible}
            handleShow={(state: boolean) => setVisible(state)}
            handleSource={onFieldChange}
            handleApply={generateKey}
        />
    </>
}

export default KeysMenu