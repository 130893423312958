import { useState, useEffect, useContext } from 'react'
import { FilterContext } from '../providers/FilterProvider'
import { getClientsAction, addClientAction, deleteClientAction, updateClientAction } from '../actions/client'
import { ActionType } from '../types/actions/client'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'
import Meta from '../types/models/meta'
import Client from '../types/models/client'
import Server from '../types/models/server'
import Item from '../types/models/item'


const useClient = () =>
{
    const { useFilter, usePagination } = useContext(FilterContext)
    const { getFilter } = useFilter
    const { getPagination } = usePagination
    const selector = useAppSelector((state) => state.clients)
    const dispatch = useAppDispatch()
    const [meta, setMeta] = useState<Meta|undefined>(selector?.meta)
    const [loading, setLoading] = useState(selector.loading)
    const [clients, setClients] = useState<Client[]>()
    const [errors, setErrors] = useState(selector.errors)
    const [success, setSuccess] = useState(selector.success)

    useEffect(() => {
        setClients(selector?.data)
        setMeta(selector?.meta)
        setLoading(selector.loading)
        setErrors(selector.errors)
        setSuccess(selector.success)
        switch (selector.type)
        {
            case ActionType.CLIENT_DELETE:
            case ActionType.CLIENT_POST:
            case ActionType.CLIENT_PUT:
                getClients()
        }
    }, [selector])

    const getClients = (page?: number) =>
    {
        const name = getFilter('searchField') === 'name' ? getFilter('searchValue') : undefined
        dispatch(getClientsAction(name, page ?? getPagination()))
    }

    const addClient = (client: Client, item?: Item, server?: Server, expired_at?: string) =>
    {
        dispatch(addClientAction(client, item, server, expired_at))
    }

    const deleteClient = (id: number) =>
    {
        dispatch(deleteClientAction(id))
    }

    const updateClient = (client: Client, item?: Item, server?: Server) =>
    {
        dispatch(updateClientAction(client, item, server))
    }

    return {
        addClient,
        getClients,
        deleteClient,
        updateClient,
        loading,
        clients,
        meta,
        errors,
        success
    }
}

export default useClient