import { useState, useEffect, useContext } from 'react'
import { FilterContext } from '../providers/FilterProvider'
import { getItemsAction, getItemAction, addItemAction, deleteItemAction, updateItemAction } from '../actions/item'
import { ActionType } from '../types/actions/item'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'
import Meta from '../types/models/meta'
import Item from '../types/models/item'
import Server from '../types/models/server'
import usePagination from './usePagination'




const useItem = () =>
{
    const { usePagination } = useContext(FilterContext)
    const { getPagination } = usePagination
    const selector = useAppSelector((state) => state.items)
    const dispatch = useAppDispatch()
    const [meta, setMeta] = useState<Meta|undefined>(selector?.meta)
    const [loading, setLoading] = useState(selector.loading)
    const [item, setItem] = useState<Item>()
    const [items, setItems] = useState<Item[]>()
    const [errors, setErrors] = useState(selector.errors)
    const [success, setSuccess] = useState(selector.success)

    useEffect(() => {
        setItems(selector?.data)
        setItem(selector?.data ? selector?.data[0]: item)
        setMeta(selector?.meta)
        setLoading(selector.loading)
        setErrors(selector.errors)
        setSuccess(selector.success)
        switch (selector.type)
        {
            case ActionType.ITEM_DELETE:
            case ActionType.ITEM_POST:
            case ActionType.ITEM_PUT:
                getItems()
        }
    }, [selector])

    const getItems = (page?: number) =>
    {
        dispatch(getItemsAction(page ?? getPagination()))
    }

    const getItem = (id: number) =>
    {
        dispatch(getItemAction(id))
    }

    const addItem = (item: Item, servers: Array<Server["name"]>) =>
    {
        dispatch(addItemAction(item, servers))
    }

    const deleteItem = (id: number) =>
    {
        dispatch(deleteItemAction(id))
    }

    const updateItem = (item: Item, servers: Array<Server["name"]>) =>
    {
        dispatch(updateItemAction(item, servers))
    }

    return {
        addItem,
        getItems,
        getItem,
        deleteItem,
        updateItem,
        loading,
        item,
        items,
        meta,
        errors,
        success
    }
}

export default useItem