export enum PromocodeType
{
    single = 'single',
    reusable = 'reusable'
}

export enum PromocodeStatus
{
    active = 'active',
    inactive = 'inactive'
}
export default interface Promocode
{
    id: number,
    uuid: string,
    name: string,
    description: string,
    discount: number,
    type: PromocodeType
    status: PromocodeStatus
}