import { useEffect } from "react"
import { Breadcrumb, Layout, Skeleton } from "antd"
import { Helmet } from "react-helmet"
import { Content } from "antd/es/layout/layout"
import { useParams } from "react-router-dom"

import Nav from "../../components/Nav"
import Header from "../../components/Header"
import useVPNBot from "../../hooks/useVPNBot"
import ItemsKeysTab from "../../components/Tab/ItemsKeysTab"
import BreadcrumbLoader from "../../components/BreadcrumbLoader"

const ItemsKeyPage = () => {

    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const { useItem, useServer } = useVPNBot()
    const { item, getItem } = useItem
    const { server, getServer, loading } = useServer
    const { item_id, server_id } = useParams()
    const breadcrumbs = [
        {title: 'Items', href: '/items'},
        {title: item?.name, href: `/items/${item?.id}`},
        {title: server?.name}
    ]

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        if (item_id)
            getItem(parseInt(item_id))
    }, [item_id])

    useEffect(() => {
        if (server_id)
            getServer(parseInt(server_id))
    }, [server_id])

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    //TODO add server obj to useServer hook

    return (
        <>
            <Helmet>
                <title>{(server?.short_name) ?? 'Item keys'} - VPNBot Admin</title>
            </Helmet>
            <Layout style={{ minHeight: '100vh' }}>
                <Header/>
                <Layout>
                    <Nav/>
                    <Layout className="site-layout">
                    <div className="tab__menu">
                        {loading ? <BreadcrumbLoader/> : <Breadcrumb className="breadcrumbs inline-block" items={breadcrumbs} /> }
                        <h1 className="tab__title">{loading ? <Skeleton.Input size="small" active /> : server?.name }</h1>
                    </div>
                        <Content className="bg-[#f0f2f5]" style={{ margin: '0 16px' }}>
                            <div className='mt-[32px] p-[24px] bg-white rounded-[4px]'>
                                <ItemsKeysTab/>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </>
    )
}

export default ItemsKeyPage;