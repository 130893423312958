import Promocode from '../types/models/promocode'
import {request} from './index'
import TokenConfig from './token'

const getPage = (page?: number) => {
    return request.get('promocodes', {
        headers: TokenConfig().headers,
        params: {
            page: page
        }
    })
}

const deletePromocode = (id: Promocode["id"]) =>
{
    return request.delete(`promocodes/${id}`, TokenConfig());
}

const addPromocode = (promocode: Promocode) =>
{
    return request.post('promocodes', {
        name: promocode.name,
        description: promocode.description,
        discount: promocode.discount,
        type: promocode.type
    }, TokenConfig())
}

const editPromocode = (promocode: Promocode) =>
{
    return request.put(`promocodes/${promocode.id}`, {
        name: promocode.name,
        description: promocode.description,
        discount: promocode.discount,
        type: promocode.type
    }, TokenConfig())
}

export { getPage, deletePromocode, addPromocode, editPromocode };