import apiResponse from "../models/apiResponse"
import reducerState from "../models/reducerState"
import Client from "../models/client"

export enum ActionType{
    CLIENT_LOADING   = 'CLIENT_LOADING',
    CLIENT_GET       = 'CLIENT_GET',
    CLIENT_GETS      = 'CLIENT_GETS',
    CLIENT_POST      = 'CLIENT_POST',
    CLIENT_PUT       = 'CLIENT_PUT',
    CLIENT_DELETE    = 'CLIENT_DELETE',
    CLIENT_ERROR     = 'CLIENT_ERROR'
}

interface actionLoading
{
    type: ActionType.CLIENT_LOADING
}

interface actionGet
{
    type: ActionType.CLIENT_GET
}

interface actionGets
{
    type: ActionType.CLIENT_GETS
}

interface actionPost
{
    type: ActionType.CLIENT_POST
}

interface actionPut
{
    type: ActionType.CLIENT_PUT
}

interface actionDelete
{
    type: ActionType.CLIENT_DELETE
}

interface actionError
{
    type: ActionType.CLIENT_ERROR
}

export type Action = actionLoading | actionGet | actionGets | actionPost | actionPut | actionDelete | actionError;

export type State = reducerState & apiResponse<Client[]>;

export const initialState: State =
{
    type: undefined,
    data: undefined,
    loading: false,
    success: true,
    errors: [],
}
