import { Action, ActionType, initialState, State } from '../types/actions/server';

const serverReducer = (state: State = initialState, action: Action): State =>
{
    //console.log({...state, ...action})
    switch(action.type)
    {
        case ActionType.SERVER_DELETE:
        case ActionType.SERVER_ERROR:
        case ActionType.SERVER_POST:
        case ActionType.SERVER_GET:
        case ActionType.SERVER_GETS:
        case ActionType.SERVER_LOADING:
        case ActionType.SERVER_PUT:
            return {...state, ...action};
        default:
            return state;
    }
}

export default serverReducer;