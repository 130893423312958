import { useState, useEffect } from 'react'
import { getCountriesAction } from '../actions/country';
import { ActionType } from '../types/actions/country'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'
import Country from '../types/models/country'


const useCountry = () =>
{
    const selector = useAppSelector((state) => state.countries)
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(selector.loading)
    const [countries, setCountries] = useState<Array<string>>()
    const [errors, setErrors] = useState(selector.errors)
    const [success, setSuccess] = useState(selector.success)

    useEffect(() => {
        setCountries(selector?.data)
        setLoading(selector.loading)
        setErrors(selector.errors)
        setSuccess(selector.success)
    }, [selector])

    const getCountries = () =>
    {
        dispatch(getCountriesAction())
    }

    return {
        getCountries,
        loading,
        countries,
        errors,
        success
    }
}

export default useCountry