import { login, logout, refresh, getUser } from '../api/authAPI'
import { AppDispatch, AppThunk } from '../store'
import { ActionType } from '../types/actions/auth'

const getUserAction = (): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.USER_LOADING,
        errors: [],
    })
    getUser().then((response) => {
        dispatch({
            loading: false,
            type: ActionType.USER_LOADING,
            errors: [],
        })
    }).catch((exception) => {
        dispatch(logoutUser())
    });
}

const authUser = (username: string, password: string): AppThunk => (dispatch: AppDispatch) =>
{
    dispatch({
        loading: true,
        type: ActionType.USER_LOADING,
        errors: [],
    })
    login(username, password).then((response) => {
        localStorage.setItem("token", response.data.data.token)
        dispatch({
            loading: false,
            type: ActionType.USER_AUTH,
            data: response.data.data,
            ...response.data
        });
    }).catch((exception) => {
        dispatch({
            loading: false,
            type: ActionType.USER_ERROR,
            errors: [exception.message]
        });
    });
}

const logoutUser = (): AppThunk => (dispatch: AppDispatch) =>
{

    dispatch({
        loading: true,
        type: ActionType.USER_LOADING,
        errors: [],
    })
    logout().then((response) => {
        localStorage.removeItem("token")
        dispatch({
            loading: false,
            type: ActionType.USER_LOGOUT,
            data: undefined,
            token: undefined,
            errors: response.data?.errors
        });
    })
}

const refreshUserToken = (): AppThunk => (dispatch: AppDispatch) =>
{
    dispatch({
        loading: true,
        type: ActionType.USER_LOADING
    })
    refresh().then((response) => {
        dispatch({
            loading: false,
            type: ActionType.USER_LOGOUT,
            data: undefined
        });
    })
}

export { authUser, logoutUser, refreshUserToken, getUserAction }