import apiResponse from "../models/apiResponse"
import reducerState from "../models/reducerState"
import Server from "../models/server"

export enum ActionType{
    SERVER_LOADING   = 'SERVER_LOADING',
    SERVER_GET       = 'SERVER_GET',
    SERVER_GETS      = 'SERVER_GETS',
    SERVER_POST      = 'SERVER_POST',
    SERVER_PUT       = 'SERVER_PUT',
    SERVER_DELETE    = 'SERVER_DELETE',
    SERVER_ERROR     = 'SERVER_ERROR'
}

interface actionLoading
{
    type: ActionType.SERVER_LOADING
}

interface actionGet
{
    type: ActionType.SERVER_GET
}

interface actionGets
{
    type: ActionType.SERVER_GETS
}

interface actionPost
{
    type: ActionType.SERVER_POST
}

interface actionPut
{
    type: ActionType.SERVER_PUT
}

interface actionDelete
{
    type: ActionType.SERVER_DELETE
}

interface actionError
{
    type: ActionType.SERVER_ERROR
}

export type Action = actionLoading | actionGet | actionGets | actionPost | actionPut | actionDelete | actionError;

export type State = reducerState & apiResponse<Server[]>;

export const initialState: State =
{
    type: undefined,
    data: undefined,
    loading: false,
    success: true,
    errors: [],
}
