import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authUser, logoutUser, getUserAction } from "../actions/user";
import User from "../types/models/user";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

const useUser = () => {
    const selector = useAppSelector((state) => state.users);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const guestPages = [
        '/login',
        '/key'
    ]

    const getToken = () =>
    {
        return selector.data ? selector.data.token : localStorage.getItem('token')
    }

    const getUsername = () =>
    {
        return selector.data ? selector.data.name : localStorage.getItem('username')
    }

    const [errors, setErrors]     = useState(selector.errors);
    const [loading, setLoading]   = useState(selector.loading);
    const [username, setUsername] = useState(getUsername());
    const [token, setToken]       = useState(getToken());
    const [isExpired, setExpired] = useState(false);
    const [success, setSuccess]   = useState(selector.success);
    const [lastPage, setLastPage] = useState(location.pathname)

    useEffect(() => {
        const token = getToken()
        const username = getUsername()
        setUsername(username)
        setToken(getToken())
        setErrors(selector.errors)
        setLoading(selector.loading)
        setSuccess(selector.success)

        if (selector.data?.name)
            localStorage.setItem('username', selector.data.name)

        if (token && !localStorage.getItem('token')?.length && selector.type !== 'USER_LOGOUT')
            localStorage.setItem('token', token);
        if (!token)
            localStorage.removeItem('token')

        if (!guestPages.includes(location.pathname) && !isLogedIn())
            navigate('/login');
        else if (location.pathname === '/login' && isLogedIn())
            navigate('/');

    }, [selector]);

    useEffect(() => {
        if (lastPage !== location.pathname)
        {
            setLastPage(location.pathname)
            dispatch(getUserAction())
        }
    }, [location])

    if (selector.data?.ttl)
        setTimeout(() => setExpired(true), selector.data?.ttl);

    const isLogedIn = () =>
    {
        return getToken() && selector.success;
    }

    const refresh = () =>
    {
        dispatch(refresh());
    }

    const logout = () =>
    {
        localStorage.removeItem('username')
        localStorage.removeItem('token')
        dispatch(logoutUser());
    }

    const login = (username: string, password: string) =>
    {
        if (selector.loading) return;
        dispatch(authUser(username, password));
    }

    return {
        isLogedIn,
        getToken,
        login,
        logout,
        refresh,
        success,
        loading,
        errors,
        username,
        token,
        isExpired
    }
}

export default useUser;