import { useEffect, useContext } from 'react'
import { message, DatePicker } from 'antd'

import useVPNBot from '../../../hooks/useVPNBot'
import { FilterContext } from '../../../providers/FilterProvider'


const PurchasesMenu = () =>
{
    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const { usePagination } = useContext(FilterContext)
    const { useTariff } = useVPNBot()
    const { getTariffs, errors } = useTariff
    const { getPagination } = usePagination
    const [messageApi, contextHolder] = message.useMessage()

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        messageApi.destroy()
        errors?.map((value: string, index: number) => {
            messageApi.open({
                type: 'error',
                content: value,
            });
        });
    }, [errors])


    //-------------------------------------------------------------------------------
    //                                  HANDLERS
    //-------------------------------------------------------------------------------

    const onDateSelect = (date: any, dateString: string) =>
    {
        getTariffs(getPagination(), undefined, dateString)
    }

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------


    return <>
        {contextHolder}
        <span className='ml-auto'>Date: <DatePicker className='w-[270px]' onChange={onDateSelect} format={'DD.MM.YYYY'} /></span>
    </>
}

export default PurchasesMenu