import { useEffect, useState, useContext } from 'react'
import { Table, Popconfirm, Button, Form, Input } from "antd"
import { EditOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons'
import { FilterContext } from '../../../providers/FilterProvider'
import { Helmet } from 'react-helmet'
import ItemsModal from '../../Modal/ItemsModal'
import Server from '../../../types/models/server'
import Item from '../../../types/models/item'
import Spinner from '../../Spinner'
import useVPNBot from '../../../hooks/useVPNBot'

import './index.scss'




//TODO Add IColumns interface for table from newDataSource

const ItemsTab = () =>
{
    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const { usePagination } = useContext(FilterContext)
    const { useItem } = useVPNBot()
    const { getItems, deleteItem, updateItem, items, loading, meta } = useItem
    const { getPagination, setPagination } = usePagination
    const [visible, setVisible ] = useState<boolean>(false)
    const [dataSource, setDataSource] = useState<any[]>()
    const [fields, setFields] = useState({
        id: 0,
        name: '',
        price: 0,
        period: 0,
        invisible: false,
        servers: []
    })

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        const newDataSource: Array<any> = [];
        items?.map((item: Item, index: number) => {
            newDataSource.push({
                key:       index,
                id:        item.id,
                uuid:      item.uuid,
                servers:   item?.servers?.map((server: Server) => server.name ),
                name:      item.name,
                price:     item.price,
                period:    item.period,
                invisible: item.invisible,
                available: item.available,
                purchased: item.purchased,
            })
        })
        setDataSource(newDataSource)
    }, [items])

    //-------------------------------------------------------------------------------
    //                                  HANDLERS
    //-------------------------------------------------------------------------------

    const onFieldChange = (name: string, value: string) =>
    {
        setFields({
            ...fields,
            [name]: value
        })
    }

    const onItemDelete = (id: number) =>
    {
        deleteItem(id)
    }

    const onItemEdit = (record: any) => {
        setFields({
            id: record.id,
            name: record.name,
            price: record.price,
            period: record.period,
            invisible: record.invisible,
            servers: record.servers
        })
        setVisible(true)
    }

    const onItemUpdate = () =>
    {
        const item = {
            id: fields.id,
            name: fields.name,
            price: fields.price,
            period: fields.period,
            invisible: fields.invisible
        } as Item
        updateItem(item, fields.servers)
    }

    const handleChange = (pagination: any, filters: any, sorter: any) =>
    {
        setPagination(pagination.current)
        getItems(pagination.current)
    }

    //-------------------------------------------------------------------------------
    //                                  TABLE COLUMNS
    //-------------------------------------------------------------------------------

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: any, record: any) => <><a href={`/items/${record.id}`}>{text}</a> {record.invisible ? <Button type='default' className={`status inactive ml-2`}>Invisible</Button> : ''}</>
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Period (days)',
            dataIndex: 'period',
            key: 'period',
        },
        {
            title: 'Available',
            dataIndex: 'available',
            key: 'available',
            render: (text: any) => <>{text == -1 ? '∞' : text}</>
        },
        {
            title: 'Purchased',
            dataIndex: 'purchased',
            key: 'purchased',
        },
        {
            title: 'Actions',
            key: 'id',
            width: 150,
            render: (text: any, record: any) => <>
                <EditOutlined onClick={() => onItemEdit(record)} className='copy-icon mr-3'/>
                <Popconfirm
                    placement="top"
                    title={"Are you sure to delete this item?"}
                    onConfirm={() => onItemDelete(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined className='delete-icon'/>
                </Popconfirm>
            </>
        }
    ];

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            <Helmet>
                <title>Items - VPNBot Admin</title>
            </Helmet>
            <ItemsModal
                title='Edit item'
                buttonTitle='Save'
                source={fields}
                show={visible}
                handleApply={onItemUpdate}
                handleShow={(state: boolean) => setVisible(state)}
                handleSource={onFieldChange}
            />
            {
                !loading ?
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        onChange={handleChange}
                        className={"items__tab"}
                        pagination={meta?.last_page === 1 ? false : {total: meta?.total, pageSize: meta?.per_page, current: getPagination()}}

                    />
                :
                    <div className='flex justify-items-center items-center flex-col w-full'>
                        <Spinner/>
                    </div>
            }
        </>
    )
}

export default ItemsTab