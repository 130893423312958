import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import Server from '../../../types/models/server';
import { useEffect, useState } from "react";
import Tariff from '../../../types/models/tariff';
import { editTariffCountry } from '../../../api/tariffAPI';

interface IUserSource {
    name: string;
    price: number;
    period: number;
    servers: Array<string>;
    invisible: boolean;
    current_server: string;
    uuid: string;
}

interface IProps {
    title: string;
    buttonTitle: string;
    show: boolean;
    handleShow: (state: boolean) => void;
    source: IUserSource;
    servers: Array<Server>;
    handleApply: () => void;
}

const UserModal = ({ title, buttonTitle, show, handleShow, source, servers, handleApply }: IProps) => {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [isServerChanged, setIsServerChanged] = useState<boolean>(false);

    useEffect(() => {
        form.setFieldsValue(source);
        setIsServerChanged(false);
    }, [form, source]);

    const handleCurrentServerChange = (value: string) => {
        setIsServerChanged(value !== source.current_server);
    };

    const onFinish = async (values: IUserSource) => {
        try {
            await editTariffCountry(source.uuid, values.current_server);
            handleApply();
            handleShow(false);
        } catch (error) {
            console.error("Error editing tariff:", error);
        }
    };

    return (
        <Modal
            title={title}
            centered
            open={show}
            onCancel={() => handleShow(false)}
            footer={[
                <Button key="cancel" onClick={() => handleShow(false)}>
                    Cancel
                </Button>,
                <Button
                    form={title}
                    key="submit"
                    htmlType="submit"
                    disabled={!isServerChanged}
                >
                    {buttonTitle}
                </Button>
            ]}
        >
            <Form
                name={title}
                autoComplete="off"
                layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input the item name' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Выбрать сервер"
                            name="current_server"
                            rules={[{ required: true, message: 'Выберите сервер' }]}
                        >
                            <Select
                                onChange={handleCurrentServerChange}
                                value={source.current_server}
                            >
                                {servers.map((server: Server) => (
                                    <Option value={server.country.short} key={server.country.short}>
                                        {server.country.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default UserModal;
