import { useState, useEffect } from 'react'
import { getPurchasesAction, addPurchaseAction, getPurchaseByTraceAction } from '../actions/purchase'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'
import { ActionType } from '../types/actions/purchase'
import Meta from '../types/models/meta'
import Purchase from '../types/models/purchase'
import Item from '../types/models/item'

const usePurchase = () =>
{
    const selector = useAppSelector((state) => state.purchases)
    const dispatch = useAppDispatch()
    const [meta, setMeta] = useState<Meta|undefined>(selector?.meta)
    const [errors, setErrors] = useState(selector.errors)
    const [loading, setLoading] = useState(selector.loading)
    const [success, setSuccess] = useState(selector.success)
    const [purchases, setPurchases] = useState<Purchase[]>()
    const [purchase, setPurchase] = useState<Purchase>()

    useEffect(() => {
        setPurchases(selector?.data)
        setPurchase(selector?.data ? selector.data[0] : purchase)
        setMeta(selector?.meta)
        setSuccess(selector?.success)
        setErrors(selector?.errors)
        setLoading(selector.loading)
        switch (selector.type)
        {
            case ActionType.PURCHASE_POST:
                getPurchases()
        }
    }, [selector])

    const getPurchases = (item?: Item["name"], page?: number, date?: string) =>
    {
        dispatch(getPurchasesAction(item, page, date))
    }

    const getPurchaseByTrace = (trace: string) =>
    {
        dispatch(getPurchaseByTraceAction(trace))
    }

    const addPurchase = (nickname: string, key: string) =>
    {
        dispatch(addPurchaseAction(nickname, key))
    }

    return {
        getPurchases,
        addPurchase,
        getPurchaseByTrace,
        loading,
        errors,
        purchase,
        purchases,
        success,
        meta
    }
}

export default usePurchase