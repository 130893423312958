import apiResponse from "../models/apiResponse"
import reducerState from "../models/reducerState"
import Purchase from "../models/purchase"

export enum ActionType{
    DEADLINE_LOADING   = 'DEADLINE_LOADING',
    DEADLINE_GET       = 'DEADLINE_GET',
    DEADLINE_GETS      = 'DEADLINE_GETS',
    DEADLINE_PUT       = 'DEADLINE_PUT',
    DEADLINE_DELETE    = 'DEADLINE_DELETE',
    DEADLINE_ERROR     = 'DEADLINE_ERROR',
    DEADLINE_PUTS      = 'DEADLINE_PUTS',
}

interface actionLoading
{
    type: ActionType.DEADLINE_LOADING
}

interface actionGet
{
    type: ActionType.DEADLINE_GET
}

interface actionGets
{
    type: ActionType.DEADLINE_GETS
}

interface actionPut
{
    type: ActionType.DEADLINE_PUT
}

interface actionDelete
{
    type: ActionType.DEADLINE_DELETE
}

interface actionError
{
    type: ActionType.DEADLINE_ERROR
}

interface actionPuts
{
    type: ActionType.DEADLINE_PUTS
}

export type Action = actionLoading | actionGet | actionGets | actionPut | actionDelete | actionError | actionPuts;

export type State = reducerState & apiResponse<Purchase[]>;

export const initialState: State =
{
    type: undefined,
    data: [],
    updating: undefined,
    loading: false,
    success: false
}
