import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"


const usePage = () =>
{
    const location = useLocation()

    const isMatching = (page: string) =>
    {
        return page === location.pathname
    }

    const getPage = () =>
    {
        return location.pathname
    }

    const getUrlSegment = (id: number) =>
    {
        const segments = location.pathname.split('/').slice()
        return segments[id] ?? null
    }

    return {
        isMatching,
        getPage,
        getUrlSegment,
    }
}

export default usePage;