import { useState, useEffect } from 'react'
import { Button, Modal, Form, Input, Row, Col, message } from 'antd'
import { PromocodeStatus, PromocodeType } from '../../../types/models/promocode'

import useVPNBot from '../../../hooks/useVPNBot'
import Spinner from '../../Spinner'
import PromocodesModal from '../../Modal/PromocodesModal'


const PromocodesMenu = () =>
{
    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const { usePromocode } = useVPNBot()
    const { addPromocode, success, errors, loading } = usePromocode
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [visible, setVisible] = useState(false)
    const [fields, setFields] = useState({
        id: 0,
        uuid: '',
        name: '',
        description: '',
        discount: 0,
        type: PromocodeType.reusable,
        status: PromocodeStatus.active
    })

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        messageApi.destroy()
        errors?.map((value: string, index: number) => {
            messageApi.open({
                type: 'error',
                content: value,
            });
        });
    }, [errors])

    useEffect(() => { if (success) setVisible(false) }, [loading])


    //-------------------------------------------------------------------------------
    //                                  HANDLERS
    //-------------------------------------------------------------------------------

    const onAddPromocode = () => addPromocode(fields)

    const onFieldChange = (name: string, value: string) =>
    {
        setFields({
            ...fields,
            [name]: value
        })
    }

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------


    return <>
        {contextHolder}
        <Button type="primary" onClick={() => setVisible(true)} disabled={loading}>Add promocode</Button>
        <PromocodesModal
            title={'Add promocode'}
            buttonTitle={'Add'}
            source={fields}
            show={visible}
            handleShow={(state: boolean) => setVisible(state)}
            handleSource={onFieldChange}
            handleApply={onAddPromocode}
        />
    </>
}

export default PromocodesMenu