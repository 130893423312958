import apiResponse from "../models/apiResponse"
import reducerState from "../models/reducerState"
import Promocode from "../models/promocode"

export enum ActionType{
    PROMOCODE_LOADING   = 'PROMOCODE_LOADING',
    PROMOCODE_GET       = 'PROMOCODE_GET',
    PROMOCODE_GETS      = 'PROMOCODE_GETS',
    PROMOCODE_PUT       = 'PROMOCODE_PUT',
    PROMOCODE_POST      = 'PROMOCODE_POST',
    PROMOCODE_DELETE    = 'PROMOCODE_DELETE',
    PROMOCODE_ERROR     = 'PROMOCODE_ERROR'
}

interface actionLoading
{
    type: ActionType.PROMOCODE_LOADING
}

interface actionGet
{
    type: ActionType.PROMOCODE_GET
}

interface actionGets
{
    type: ActionType.PROMOCODE_GETS
}

interface actionPost
{
    type: ActionType.PROMOCODE_POST
}

interface actionPut
{
    type: ActionType.PROMOCODE_PUT
}

interface actionDelete
{
    type: ActionType.PROMOCODE_DELETE
}

interface actionError
{
    type: ActionType.PROMOCODE_ERROR
}

export type Action = actionLoading | actionGet | actionGets | actionPost | actionPut | actionDelete | actionError;

export type State = reducerState & apiResponse<Promocode[]>;

export const initialState: State =
{
    type: undefined,
    data: [],
    loading: false,
    success: false
}
