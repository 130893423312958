import { useState, useEffect, useContext } from 'react'
import { Table, Popconfirm, message } from 'antd'
import { useParams } from 'react-router-dom'
import { DeleteOutlined, CopyOutlined } from '@ant-design/icons'
import { FilterContext } from '../../../providers/FilterProvider'
import Key from '../../../types/models/key'
import useVPNBot from '../../../hooks/useVPNBot'
import Spinner from '../../Spinner'
import Server from '../../../types/models/server'
import Tariff from '../../../types/models/tariff'

import './index.scss'

const KeysTab = () =>
{
    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const [ messageApi, contextHolder ] = message.useMessage();
    const { usePagination } = useContext(FilterContext)
    const { useTariff, useKey } = useVPNBot()
    const { tariffs, loading, meta, getServerTariffs } = useTariff
    const { deleteKey, updateKey } = useKey
    const { getPagination, setPagination } = usePagination
    const { server_id } = useParams()
    const [ dataSource, setDataSource ] = useState<any[]>()
    const [ edit, setEdit ] = useState<Key["id"] | undefined>()

    const [fields, setFields] = useState({
        name: '',
        server: ''
    })

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        const newDataSource: Array<any> = [];
        tariffs?.map((tariff: Tariff, index: number) => {
            newDataSource.push({
                key:          index,
                id:           tariff.key.id,
                created_at:   tariff.created_at,
                expirationAt: tariff.expired_at,
                username:     tariff.client.name.length ? tariff.client.name : tariff.client.chat_id,
                access_url:   tariff.key.access_url,
            })
        })
        setDataSource(newDataSource)
    }, [tariffs])

    useEffect(() => {
        if (server_id) getServerTariffs(parseInt(server_id))
    }, [server_id])

    //-------------------------------------------------------------------------------
    //                                  HANDLERS
    //-------------------------------------------------------------------------------


    const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        setFields({
            ...fields,
            [event.target.name]: event.currentTarget.value
        })
    }

    const onEdit = (id: Key["id"], name: Key["name"], server: Server["name"]) => {
        !edit ? setEdit(id) : setEdit(undefined)
        setFields({
            name: name,
            server: server
        })
    }

    const onUpdate = (id: Key["id"]) =>
    {
        updateKey(id, fields.name, fields.server)
        setEdit(undefined)
    }

    const onDelete = (id: Key["id"]) => {
        deleteKey(id)
        if (server_id)
            getServerTariffs(parseInt(server_id))
    }

    const handleChange = (pagination: any, filters: any, sorter: any) =>
    {
        setPagination(pagination.current)
        if (server_id) getServerTariffs(parseInt(server_id), pagination.current)
    }

    const copyKey = (key: string) =>
    {
        navigator.clipboard.writeText(key)
        messageApi.open({
            type: 'success',
            content: `Key successfully copied! `,
        });
    }

    //-------------------------------------------------------------------------------
    //                                TABLE COLUMNS
    //-------------------------------------------------------------------------------


    const columns = [
        {
            title: 'Creation date',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Expiration date',
            dataIndex: 'expirationAt',
            key: 'expirationAt',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'KEY',
            dataIndex: 'access_url',
            key: 'access_url',
            render: (text: any, record: any) => <><CopyOutlined onClick={() => copyKey(record.access_url)} className='copy-icon mr-3'/> {text.substring(0,10)}...</>
        },
        {
            title: 'Actions',
            key: 'id',
            width: 150,
            render: (text: any, record: any) => <>
                <Popconfirm
                    placement="top"
                    title={"Are you sure to delete this key"}
                    onConfirm={() => onDelete(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined className='delete-icon'/>
                </Popconfirm>
            </>
        }
    ]

    //-------------------------------------------------------------------------------
    //                                   RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            {contextHolder}
            {!loading ?
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    onChange={handleChange}
                    pagination={meta?.last_page === 1 ? false : {total: meta?.total, pageSize: meta?.per_page, current: getPagination()}}
                    className="keys__tab"
                />
            :
                <div className='flex justify-items-center items-center flex-col w-full'>
                    <Spinner/>
                </div>
            }
        </>
    )
}

export default KeysTab