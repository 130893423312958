import { AppDispatch, AppThunk } from '../store'
import { ActionType } from '../types/actions/tab'
import TabItem from '../types/models/tabItem'

const setTabKey = (item: TabItem): AppThunk => (dispatch: AppDispatch) => {
    dispatch({
        type: ActionType.TAB_SET,
        data: {
            ...item
        }
    })
}

export { setTabKey }