import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Table, Popconfirm, Button } from "antd"
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { FilterContext } from '../../../providers/FilterProvider'
import Promocode, { PromocodeStatus, PromocodeType } from '../../../types/models/promocode'
import useVPNBot from '../../../hooks/useVPNBot'

import PromocodesModal from '../../Modal/PromocodesModal'
import Spinner from '../../Spinner'

import './index.scss'


const PromocodesTab = () =>
{

    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const { usePagination } = useContext(FilterContext)
    const { usePromocode } = useVPNBot()
    const {deletePromocode, getPromocodes, editPromocode, promocodes, loading, success, meta} = usePromocode
    const {getPagination, setPagination} = usePagination
    const [edit, setEdit] = useState<Promocode["uuid"] | undefined>()
    const [visible, setVisible] = useState<boolean>(false)
    const [dataSource, setDataSource] = useState<any[]>()
    const [fields, setFields] = useState({
        id: 0,
        uuid: '',
        name: '',
        description: '',
        discount: 0,
        type: PromocodeType.reusable,
        status: PromocodeStatus.active
    })

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        const newDataSource: Array<any> = [];
        promocodes?.map((promocode: any, index: number) => {
            newDataSource.push({
                key:         index,
                id:          promocode.id,
                uuid:        promocode.uuid,
                name:        promocode.name,
                description: promocode.description.length ? promocode.description : '-',
                discount:    promocode.discount,
                type:        promocode.type,
                status:      promocode.status
            })
        })
        setDataSource(newDataSource)
    }, [promocodes])

    useEffect(() => {
        if (success)
            setVisible(false)
    }, [loading])

    useEffect(() => getPromocodes(), [])

    //-------------------------------------------------------------------------------
    //                                  HANDLERS
    //-------------------------------------------------------------------------------

    const handleChange = (pagination: any, filters: any, sorter: any) =>
    {
        setPagination(pagination.current)
        getPromocodes(pagination.current)
    }

    const onPromocodeDelete = (id: Promocode["id"]) => deletePromocode(id)

    const onPromocodeUpdate = () => {
        editPromocode(fields)
        setEdit(undefined)
    }

    const onPromocodeEdit = (record: Promocode) => {
        setVisible(true)
        setFields(record)
    }

    const onFieldChange = (name: string, value: string) =>
    {
        setFields({
            ...fields,
            [name]: value
        })
    }

    //-------------------------------------------------------------------------------
    //                                 TABLE COLUMNS
    //-------------------------------------------------------------------------------

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Discount %',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: any, record: any) => <>
                <Button type='default' className={`status px-2 ${text.replace(' ', '-')}`}>{text}</Button>
            </>
        },
        {
            title: 'Actions',
            key: 'id',
            width: 150,
            render: (text: any, record: any) => <>
                <EditOutlined onClick={() => onPromocodeEdit(record)} className='copy-icon mr-3'/>
                <Popconfirm
                    placement="top"
                    title={"Are you sure to delete this promocode?"}
                    onConfirm={() => onPromocodeDelete(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined className='delete-icon'/>
                </Popconfirm>
            </>
        }
    ];

    //-------------------------------------------------------------------------------
    //                                 RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            <Helmet>
                <title>Promocodes - VPNBot Admin</title>
            </Helmet>
            <PromocodesModal
                title={'Edit promocode'}
                buttonTitle={'Save'}
                source={fields}
                show={visible}
                handleShow={(state: boolean) => setVisible(state)}
                handleSource={onFieldChange}
                handleApply={onPromocodeUpdate}
            />
            {
                !loading ?
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        onChange={handleChange}
                        pagination={meta?.last_page === 1 ? false : {total: meta?.total, pageSize: meta?.per_page, current: getPagination()}}
                        className={"promocodes__tab"}
                    />
                :
                    <div className='flex justify-items-center items-center flex-col w-full'>
                        <Spinner/>
                    </div>
            }
        </>
    )
}

export default PromocodesTab