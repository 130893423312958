import Item from '../types/models/item';
import Server from '../types/models/server';
import { request, telegramRequest } from './index'
import TokenConfig from './token'

const getTariffs = (page?: number, item?: Item["name"], date?: string) => {
    return request.get('tariffs', {headers: TokenConfig().headers, params: {page: page, item: item, date: date}});
}

const getServerTariffs = (server: Server["id"], page?: number) => {
    return request.get('tariffs', {headers: TokenConfig().headers, params: {page: page, server: server}});
}

const getTariffByUUID = (uuid: string) => {
    return telegramRequest.get(`tariffs/${uuid}`, {headers: TokenConfig().headers});
}

const deleteTariff = (id: number) => {
    return request.delete(`tariffs/${id}`, TokenConfig());
}

const editTariffCountry = (uuid: string, country: string) => {
    return request.post(`tariffs/${uuid}/countries`, {
        country: country
    }, TokenConfig());
};

export { getTariffs, getServerTariffs, getTariffByUUID, deleteTariff, editTariffCountry }