
import {useEffect} from 'react'
import { Button, Form, Input, Row, Col, message } from 'antd'
import { UserOutlined, UnlockOutlined } from '@ant-design/icons'
import useUser from '../../hooks/useUser'
import Logo from '../Logo'
import Loader from '../Loader';
import { Helmet } from 'react-helmet';
import './index.scss'

interface loginValues{
    username: string,
    password: string
}

const AuthForm = () => {

    const [messageApi, contextHolder] = message.useMessage();
    const {login, errors, loading} = useUser();

    useEffect(() => {
        messageApi.destroy();
    }, []);

    const onFinish = (values: loginValues) => {
        if (loading) return;
        login(values.username, values.password);
    };

    const onFinishFailed = (errorInfo: any) => {
        messageApi.destroy()
        messageApi.open({
            type: 'error',
            content: errorInfo?.errorFields[0]?.errors[0],
        });
    };

    useEffect(() => {
        messageApi.destroy()
        errors?.map((value: string, index: number) => {
            messageApi.open({
                type: 'error',
                content: value,
            });
        });
    }, [errors])

    return (
        <div className='login w-100 flex flex-col justify-center items-center'>
            {contextHolder}
            <Helmet>
                <title>Login - VPNBot Admin</title>
            </Helmet>
            <Logo/>
            <Form
                name="login"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ width: '100%'}}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className='mt-5'
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="username"
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                            rules={[{ required: true}]}
                            className="select-none uppercase mb-1"
                            style={{height: '40px', marginBottom: '24px'}}
                        >
                            <Input style={{height: '40px'}} prefix={<UserOutlined/>} placeholder='Your username'/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="password"
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                            rules={[{ required: true}]}
                            className="select-none uppercase"
                            style={{height: '40px', marginBottom: '24px'}}
                        >
                            <Input.Password style={{height: '40px'}} height={40} prefix={<UnlockOutlined/>} placeholder='Your password'/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item wrapperCol={{ span: 24 }} style={{marginBottom: '0'}}>
                    <Button type="default" htmlType="submit" className='px-6 w-full' style={{backgroundColor: '#1890ff', borderRadius: '0', height: '40px'}}>
                        Login
                    </Button>
                </Form.Item>
            </Form>
            <Loader visible={loading}/>
        </div>
    )
}

export default AuthForm;