import './index.scss'

const Logo = () => {
    return (
        <div className="px-[12px] py-[8px] border border-[#434343] text-[#434343] select-none logo">
            <span className="font-bold text-[18px] leading-[22px]">Bro</span>&nbsp;
            <span className="font-normal text-[18px] leading-[22px]">VPN</span>
        </div>
    )
}

export default Logo;