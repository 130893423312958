import { Layout } from "antd"
import { Helmet } from "react-helmet"
import { Content } from "antd/es/layout/layout"

import Nav from "../../components/Nav"
import Header from "../../components/Header"
import UsersTab from "../../components/Tab/UsersTab"
import UsersMenu from "../../components/Menu/UsersMenu"


const ClientsPage = () => {

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            <Helmet>
                <title>Users - VPNBot Admin</title>
            </Helmet>
            <Layout style={{ minHeight: '100vh' }}>
                <Header/>
                <Layout>
                    <Nav/>
                    <Layout className="site-layout">
                        <div className="tab__menu">
                            <h1 className="tab__title">Clients</h1>
                            <UsersMenu/>
                        </div>
                        <Content className="bg-[#f0f2f5]" style={{ margin: '0 16px' }}>
                            <div className='mt-[32px] p-[24px] bg-white rounded-[4px]'>
                                <UsersTab/>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </>
    )
}

export default ClientsPage;