import apiResponse from "../models/apiResponse"
import reducerState from "../models/reducerState"
import User from "../models/user"

export enum ActionType{
    USER_LOADING   = 'USER_LOADING',
    USER_AUTH      = 'USER_AUTH',
    USER_LOGOUT    = 'USER_LOGOUT',
    USER_REFRESH   = 'USER_REFRESH',
    USER_ERROR     = 'USER_ERROR'
}

interface actionLoading
{
    type: ActionType.USER_LOADING
}

interface actionAuth
{
    type: ActionType.USER_AUTH
}

interface actionLogout
{
    type: ActionType.USER_LOGOUT
}

interface actionRefresh
{
    type: ActionType.USER_REFRESH
}

interface actionError
{
    type: ActionType.USER_ERROR
}

export type Action = actionLoading | actionAuth | actionLogout | actionRefresh | actionError;

export type State = reducerState & apiResponse<User>;

export const initialState: State =
{
    type: undefined,
    data: undefined,
    loading: false,
    success: true,
    errors: [],
}
