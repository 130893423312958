import { AppThunk } from '../store'
import { ActionType } from '../types/actions/key'
import { getPage, deleteKey, changeKey, addKey } from '../api/keyAPI'
import Key from '../types/models/key'
import Server from '../types/models/server'

const getKeysAction = (server?: number, page?: number): AppThunk => (dispatch: any) => {
    dispatch({
        loading: true,
        type: ActionType.KEY_LOADING
    })
    getPage(server, page).then((response) => {
        const keyResponse = response.data as Key[]
        dispatch({
            loading: false,
            type: ActionType.KEY_GETS,
            ...keyResponse
        })
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.KEY_ERROR,
            ...exception.response.data
        })
    })
}

const deleteKeyAction = (id: number): AppThunk => (dispatch: any) => {
    dispatch({
        loading: true,
        type: ActionType.KEY_LOADING
    })
    deleteKey(id).then((response) => {
        const keyResponse = response.data as Key[]
        dispatch({
            loading: false,
            type: ActionType.KEY_DELETE,
            ...keyResponse
        })
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.KEY_ERROR,
            ...exception.response.data
        })
    })
}

const changeKeyAction = (id: Key["id"], name: Key["name"], server: Server["name"]): AppThunk => (dispatch: any) => {
    dispatch({
        loading: true,
        type: ActionType.KEY_LOADING
    })
    changeKey(id, name, server).then((response) => {
        const keyResponse = response.data as Key[]
        dispatch({
            loading: false,
            type: ActionType.KEY_PUT,
            ...keyResponse
        })
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.KEY_ERROR,
            ...exception.response.data
        })
    })
}

const addKeyAction = (amount: number, serverId: Server["id"]):AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.KEY_LOADING
    })
    addKey(amount, serverId).then((response) => {
        const keyResponse = response.data as Key[]
        dispatch({
            loading: false,
            type: ActionType.KEY_POST,
            ...keyResponse
        })
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.KEY_ERROR,
            ...exception.response.data
        })
    })
}

export { getKeysAction, deleteKeyAction, changeKeyAction, addKeyAction }