import apiResponse from "../models/apiResponse"
import reducerState from "../models/reducerState"
import Item from "../models/item"

export enum ActionType{
    ITEM_LOADING   = 'ITEM_LOADING',
    ITEM_GET       = 'ITEM_GET',
    ITEM_GETS      = 'ITEM_GETS',
    ITEM_POST      = 'ITEM_POST',
    ITEM_PUT       = 'ITEM_PUT',
    ITEM_DELETE    = 'ITEM_DELETE',
    ITEM_ERROR     = 'ITEM_ERROR'
}

interface actionLoading
{
    type: ActionType.ITEM_LOADING
}

interface actionGet
{
    type: ActionType.ITEM_GET
}

interface actionGets
{
    type: ActionType.ITEM_GETS
}

interface actionPost
{
    type: ActionType.ITEM_POST
}

interface actionPut
{
    type: ActionType.ITEM_PUT
}

interface actionDelete
{
    type: ActionType.ITEM_DELETE
}

interface actionError
{
    type: ActionType.ITEM_ERROR
}

export type Action = actionLoading | actionGet | actionGets | actionPost | actionPut | actionDelete | actionError;

export type State = reducerState & apiResponse<Item[]>;

export const initialState: State =
{
    type: undefined,
    data: undefined,
    loading: false,
    success: true,
    errors: [],
}
