import { Action, ActionType, initialState, State } from '../types/actions/promocode';

const promocodeReducer = (state: State = initialState, action: Action): State =>
{
    switch(action.type)
    {
        case ActionType.PROMOCODE_LOADING:
        case ActionType.PROMOCODE_GET:
        case ActionType.PROMOCODE_GETS:
        case ActionType.PROMOCODE_POST:
        case ActionType.PROMOCODE_PUT:
        case ActionType.PROMOCODE_DELETE:
        case ActionType.PROMOCODE_ERROR:
            return {...state, ...action};
        default:
            return state;
    }
}

export default promocodeReducer;